<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    :title="titleAddCharge"
    @hidden="closeModal"
  >
    <validation-observer ref="form">
      <template v-if="edit">
        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider name="PROGRAM">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >PROGRAM
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="client.program_name"
                  :disabled="true"
                />
              </b-input-group> </validation-provider
          ></b-col>
          <b-col lg="6" class="mt-1">
            <validation-provider name="CLIENT">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CLIENT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="client.client_name"
                  :disabled="true"
                />
              </b-input-group> </validation-provider
          ></b-col>
        </b-row>

        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CONCEPT"
              rules="required"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CONCEPT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <v-select
                  v-model="description"
                  :options="typecharges"
                  :reduce="(val) => val.id"
                  :state="errors[0] ? false : null"
                  @input="loadFeeLossBySurcharge()"
                />
              </b-input-group>

              <label v-if="errors[0]" class="text-danger"
                >Concept {{ errors[0] }}</label
              >
            </validation-provider>
            <validation-provider
              v-if="valDescription"
              v-slot="{ errors }"
              name="OTHERS"
              rules="required"
            >
              <b-input-group class="mt-1">
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >OTHERS
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <b-input
                  v-if="finishedLoading"
                  v-model="notcharges"
                  :state="errors[0] ? false : null"
                  placeholder="Specify"
                >
                  Specify
                </b-input>
              </b-input-group>
              <label v-if="errors[0]" class="text-danger"
                >Specify {{ errors[0] }}</label
              >
            </validation-provider>
          </b-col>
          <b-col v-if="validateCreditor" lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CREDITOR"
              rules="required"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CREDITOR
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-select
                  v-if="finishedLoading"
                  v-model="creditorSelect"
                  :state="errors[0] ? false : null"
                  value-field="id"
                >
                  <b-form-select-option
                    v-for="item in creditors"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{
                      item.creditor_name + ("(" + item.account + ")")
                    }}</b-form-select-option
                  >
                </b-form-select>
              </b-input-group>
            </validation-provider></b-col
          >
          <b-col lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT"
              rules="money-2"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >AMOUNT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <money
                  v-if="finishedLoading"
                  id="input-1a"
                  v-model="ammount"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                    opacity: isDarkSkin
                      ? errors[0] === 'The amount must be greater than 0.00' &&
                        validador
                        ? '1'
                        : '0.3'
                      : '1',
                  }"
                  v-bind="maskMoney"
                  class="form-control"
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </label>
            </validation-provider></b-col
          >
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider name="PROGRAM">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >PROGRAM
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="client.program_name"
                  :disabled="true"
                />
              </b-input-group> </validation-provider
          ></b-col>
          <b-col lg="6" class="mt-1">
            <validation-provider name="CLIENT">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CLIENT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="client.client_name"
                  :disabled="true"
                />
              </b-input-group> </validation-provider
          ></b-col>
        </b-row>

        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CONCEPT"
              rules="required"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CONCEPT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <b-select
                  v-if="finishedLoading"
                  v-model="description"
                  :options="typecharges"
                  :state="errors[0] ? false : null"
                  value-field="id"
                  text-field="description"
                  @input="loadFeeLossBySurcharge"
                />
              </b-input-group>

              <label v-if="errors[0]" class="text-danger"
                >Concept {{ errors[0] }}</label
              >
            </validation-provider>
          </b-col>
          <b-col lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT"
              rules="money-2"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >AMOUNT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <money
                  v-if="finishedLoading"
                  id="input-1a"
                  v-model="ammount"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                  }"
                  v-bind="maskMoney"
                  :disabled="description == null"
                  class="form-control"
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </label>
            </validation-provider></b-col
          >
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="Payment_Method"
              rules="required"
            >
              <b-form-group label="Payment Method">
                <b-form-radio-group
                  v-model="method_payment"
                  :state="errors[0] ? false : null"
                  :options="optionsPaymentMethod"
                  @change="type_payment = null"
                  name="radio-payment"
                />
                <label v-if="errors[0]" class="text-danger"
                  >Payment Method {{ errors[0] }}</label
                >
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="method_payment === 1 || method_payment === 2">
          <b-col md="6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Type Payment">
              <b-form-radio-group
                v-model="type_payment"
                :options="optionsTypePayment"
                :aria-describedby="ariaDescribedby"
                name="radio-inline"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="method_payment === 2" md="6">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT_PARTIAL"
              rules="money-2"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >AMOUNT PARTIAL
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <money
                  id="input-1"
                  v-model="ammount_partial"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                    opacity: isDarkSkin
                      ? errors[0] === 'The amount must be greater than 0.00' &&
                        validador
                        ? '1'
                        : '0.3'
                      : '1',
                  }"
                  v-bind="maskMoney"
                  class="form-control"
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </label>
            </validation-provider></b-col
          >
        </b-row>
        <b-row>
          <b-col v-if="type_payment === 2 && method_payment !== 3" md="6">
            <validation-provider name="TYPE">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >TYPE
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <b-select
                  v-if="finishedLoading"
                  v-model="types"
                  :options="optionsType"
                  value-field="value"
                  text-field="name"
                />
              </b-input-group> </validation-provider
          ></b-col>

          <b-col v-if="type_payment === 2 && method_payment !== 3" md="6">
            <validation-provider name="PROGRAM">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >SERIAL NUMBER
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input v-if="finishedLoading" v-model="specify" />
              </b-input-group> </validation-provider
          ></b-col>
        </b-row>
        <b-row v-if="type_payment === 1">
          <b-col>
            <b-card no-body>
              <b-table
                ref="refCards"
                small
                show-empty
                sticky-header="50vh"
                :busy="isBusy"
                :fields="fields"
                :items="searchCards"
                class="font-small-3 text-center"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(radio)="data">
                  <b-form-radio
                    v-model="card_id"
                    :disabled="data.item.count_declined >= 2"
                    :value="data.item.id"
                    :name="'card' + data.item.id"
                    @change="changeCard(data.item.type_card)"
                  />
                </template>

                <template v-slot:cell(card_holder_name)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.cardholdername }}
                  </p>
                </template>
                <template v-slot:cell(card_number)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
                  </p>
                </template>
                <template v-slot:cell(type)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.type_card }}
                  </p>
                </template>
                <template v-slot:cell(mm)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.card_expi_month }}
                  </p>
                </template>
                <template v-slot:cell(yy)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.card_expi_year }}
                  </p>
                </template>
                <template v-slot:cell(cvc)="data">
                  <p class="mb-0 font-weight-bold">
                    {{
                      data.item.cardsecuritycode != null
                        ? data.item.cardsecuritycode.length == 3
                          ? "XX" + data.item.cardsecuritycode.substr(2)
                          : "XXX" + data.item.cardsecuritycode.substr(3)
                        : ""
                    }}
                  </p>
                </template>
                <template v-slot:cell(created_by)="data">
                  <p class="mb-0 font-weight-bold">
                    <span>{{ data.item.created_by }}</span>
                    <br />
                    <span>{{ data.item.created_at | myGlobalDay }}</span>
                  </p>
                </template>
                <template v-slot:cell(actions)="data">
                  <feather-icon
                    size="16"
                    icon="TrashIcon"
                    class="cursor-pointer text-danger"
                    @click="openDeleteCreditCard(data.item.id)"
                  />
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-if="type_payment === 1">
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button variant="success" @click="openCreateCreditCard">
                ADD</b-button
              >
            </div>
          </b-col>
        </b-row></template
      >
    </validation-observer>

    <delete-credit-card
      v-if="deleteCreditOn"
      :card_id="card_id"
      :lead_id="client.lead_id"
      @refresh="refresh"
      @close="closeDeleteCreditCard"
    />

    <template #modal-footer>
      <b-button
        v-if="edit"
        class="d-flex justify-content-center"
        variant="primary"
        @click="editCharge"
        :disabled="spinnerOn"
      >
        <b-spinner v-if="spinnerOn" small />
        UPDATE
      </b-button>
      <b-button
        v-else
        class="d-flex justify-content-center"
        variant="primary"
        @click="insertCharge"
        :disabled="spinnerOn"
      >
        <b-spinner v-if="spinnerOn" small class="mr-1" />
        SAVE
      </b-button>
    </template>

    <create-credit-card
      v-if="createCreditOn"
      :lead="client.lead_id"
      @reload="refresh"
      @close="closeCreateCreditCard"
    />
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/modals/fields.data";
import DeleteCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/DeleteCreditCard.vue";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    DeleteCreditCard,
    CreateCreditCard,
  },
  props: {
    edit: {
      type: Object,
      default: null,
    },
    titleAddCharge: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      createCreditOn: false,
      creditorSelect: "",
      notcharges: null,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      types: null,
      type_card: "",
      spinnerOn: false,
      isBusy: false,
      cantcards: [],
      card_id: null,
      fields: dataFields,
      specify: "",
      type_payment: null,
      method_payment: null,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      finishedLoading: false,
      typecharges: [],
      valDescription: false,

      description: null,
      amount_charge: null,
      validateCreditor: false,
      optionsPaymentMethod: [
        { text: "Direct Payment", value: 1 },
        { text: "Parcial", value: 2 },
        { text: "Fee Charge", value: 3 },
      ],
      optionsTypePayment: [
        { text: "Credit Card", value: 1 },
        { text: "Others", value: 2 },
      ],
      descriptionName: null,
      optionsType: [
        { name: "Cash", value: 1 },
        { name: "Check", value: 2 },
        { name: "Money Order", value: 3 },
        { name: "Cashier Check", value: 4 },
      ],
      deleteCreditOn: false,
      ammount: 0,
      ammount_partial: 0,
      validarMoney: false,
      creditors: [],

      //
      errorConcept: false,
      errorAmmount: false,
      errorPayment: false,
      errorType: false,
      errorCard: false,
      errorType2: false,
      errorSerial: false,
      errorAmmountPartial: false,
      errorConceptSpecify: false,
      errosAutorize: [],
      messageAutorize: [],
      errosList: false,
      messageList: false,
      responseCode: "",
      validador: false,
    };
  },
  created() {},

  async mounted() {
    this.ownControl = true;
    if (this.edit) {
      await this.editChargeInfo();
    }

    await this.search();

    await this.getCreditors();
    this.finishedLoading = true;
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
    modulId() {
      return this.currentUser.modul_id;
    },
  },

  watch: {
    description(oldVal) {
      const myVal = this.typecharges.find((element) => {
        if (element.id === oldVal) {
          return element.description ? element.description : null;
        }
      });

      this.descriptionName = myVal ? myVal.description : null;

      if (this.description === 5) {
        this.valDescription = true;
      } else {
        this.valDescription = false;
      }

      if (
        this.description === 15 ||
        this.description === 16 ||
        this.description === 17
      ) {
        this.validateCreditor = true;
      } else {
        this.validateCreditor = false;
      }
    },
  },
  methods: {
    async editChargeInfo() {
      this.creditorSelect = this.edit.idcreditor;
      this.description = this.edit.t_charge;
      this.ammount = this.edit.amount;
      this.notcharges = this.edit.specify;
    },

    closeCreateCreditCard() {
      this.createCreditOn = false;
    },
    openCreateCreditCard() {
      this.createCreditOn = true;
    },
    refresh() {
      this.$refs.refCards.refresh();
    },
    changeCard(id) {
      this.type_card = id;
    },
    async search() {
      try {
        const { data } = await ClientsOptionsServices.getAllSubCharges({
          entrances_id: 3,
          module_id: this.modulId,
        });
        this.typecharges = data.map((charge) => {
          return { ...charge, description: charge.name };
        });
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async loadFeeLossBySurcharge() {
      this.addPreloader();
      const { data } = await ClientsOptionsServices.loadFeeLossSubCharge({
        subcharge_id: this.description,
      });
      this.ammount = data[0].fee;
      this.removePreloader();
    },

    closeModal() {
      this.$emit("close");
    },

    openDeleteCreditCard(id) {
      this.card_id = id;
      this.deleteCreditOn = true;
    },
    closeDeleteCreditCard() {
      this.deleteCreditOn = false;
    },

    async searchCards() {
      try {
        const data = await ClientsOptionsServices.searchcards({
          id: this.client.lead_id,
        });
        this.cantcards = data.data;

        return this.cantcards;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    // eslint-disable-next-line consistent-return
    async getCreditors() {
      try {
        const data = await ClientsOptionsServices.getCreditors({
          id: this.$route.params.idClient,
        });
        this.creditors = data.data;

        return this.creditors;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    // eslint-disable-next-line consistent-return
    async insertCharge() {
      const response = await this.showConfirmSwal();
      if (response.isConfirmed) {
        try {
          this.validador = true;
          const result = await this.$refs.form.validate();

          if (result) {
            this.addPreloader();
            this.spinnerOn = true;
            const params = {
              id: this.$route.params.idClient,
              account: this.client.account,
              concept: this.description,
              ammount: this.ammount.toString(),
              ammount_partial:
                this.ammount_partial === 0
                  ? (this.ammount_partial = "")
                  : this.ammount_partial.toString(),
              method_payment: this.method_payment.toString(),
              card_id: this.card_id ? this.card_id : (this.card_id = ""),
              type_payment: this.type_payment
                ? this.type_payment.toString()
                : (this.type_payment = ""),
              type: this.types ? this.types.toString() : 0,
              specify: this.specify,
              user_id: this.currentUser.user_id,
              notcharges: this.notcharges
                ? this.notcharges
                : this.descriptionName,
              programid: this.client.program_id,
              type_process: 0,
              modul: this.modulId,
              merchant: this.type_card ? this.type_card : (this.type_card = ""),
              idapp: null,
              nameCharge: null,
              settlementcost: null,
              idcreditor:
                this.creditorSelect == null || this.creditorSelect === ""
                  ? null
                  : this.creditorSelect,
            };

            const { data } = await ClientsOptionsServices.saveCharge(params);

            this.spinnerOn = false;

            this.showGenericToast({
              position: "top-right",
              variant: data.info.variant,
              icon: data.info.icon,
              title: data.info.title,
              text: data.message,
            });

            this.closeModal();

            if (this.type_payment === 1) {
              this.ammount = 0;
              this.card_id = null;
              this.$refs.refCards.refresh();
            }
            this.$emit("refresh");
          }
        } catch (e) {
          this.spinnerOn = false;
          this.showErrorSwal(e);
          return [];
        } finally {
          this.removePreloader();
        }
      }
    },

    async editCharge() {
      try {
        this.validador = true;
        const result = await this.$refs.form.validate();
        const resultSwal = await this.showConfirmSwal();
        if (resultSwal.isConfirmed) {
          if (result) {
            this.spinnerOn = true;
            const params = {
              p_type_charge: this.description,
              p_id: this.edit.id,
              p_charge: this.notcharges === "" ? null : this.notcharges,
              p_idcreditor:
                this.creditorSelect == null || this.creditorSelect == ""
                  ? null
                  : this.creditorSelect,
              p_settlementcost: null,
            };

            const data = await ClientsOptionsServices.getChargeAccountsById(
              params
            );

            if (data.status === 200) {
              this.$emit("refresh");
              this.showSuccessSwal();
              this.closeModal();
            }
          }
        }
      } catch (e) {
        this.spinnerOn = false;
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
.space {
  width: 63%;
}
.title_lable {
  width: 150px;
}
</style>
